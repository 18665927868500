import React, { useEffect, useState, useRef } from "react";
import Typed from "react-typed";
import { Tilt } from "react-tilt";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper/modules";
import Install from "./Install";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getGlobalState, setGlobalState } from "../store";
//import HabibiPlates from "../contracts/contracts/deployables/HabibiPlatesContract.sol/HabibiPlatesContract.json";
import abi from "../_contract_abi.json";
import { ethers } from "ethers";
import { countdown } from "./countdown";

import { readContract, writeContract } from "@wagmi/core";
import { useContractRead } from "wagmi";

// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, Web3Button } from "@web3modal/react";
import {
  configureChains,
  createConfig,
  WagmiConfig,
  useAccount,
  useWalletClient,
} from "wagmi";
import { goerli } from "wagmi/chains";
import { Buffer } from "buffer";
import { resolve } from "path";
window.Buffer = window.Buffer || require("buffer").Buffer;

const keccak256 = require("keccak256");
const { MerkleTree } = require("merkletreejs");
const contractAddress = "0x1222b260167ab45Ebf9a7DD9489d41A2a66E4c4f";

const maxMints = 10;

const chains = [goerli];
const projectId = process.env.REACT_APP_PROJECT_ID;
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

const connectedAccount = getGlobalState("connectedAccount");
// const accounts = await window.ethereum.request({ method: "eth_accounts" });

const wallet = "0x98aD7efe17784c73e7FE168C4E5876134bb1D916";
// const wallet = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";

const Hero = () => {
  const { data: walletClient, isError, isLoading } = useWalletClient();
  const include = "merkle_proofs";
  const query = new URLSearchParams({
    chain: "goerli",
    include,
  });

  const prevCountRef = useRef(1);

  let whitelistAddresses = [
    "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266", // The address in remix
    "0x98aD7efe17784c73e7FE168C4E5876134bb1D916", // The address in remix
    "0x70997970C51812dc3A010C7d01b50e0d17dc79C8",
  ];

  console.log("walletClient?.account.address: ", walletClient?.account.address);

  const leafNodes = whitelistAddresses.map((addr) => keccak256(addr));
  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

  const rootHash = merkleTree.getRoot();
  console.log("Whitelist Merkle Tree\n", merkleTree.toString());
  console.log("Root Hash: ", rootHash);

  const leaf = keccak256("0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266");
  const claimingAddress = leaf;
  const hexProof = merkleTree.getHexProof(claimingAddress);

  console.log(hexProof);

  const { isConnected } = useAccount();
  const [infoData, setData] = useState("Loading...");
  const [proofData, setProofData] = useState();
  const [mintingButtonActive, setMintingButtonActive] = useState(false);
  const [presaleCountdown, setPresaleCountdown] = useState("---");
  const [totalPrice, setTotalPrice] = useState();
  const [startTimeState, setStartTimeState] = useState(new Date());
  const [mintStatus, setMintStatus] = useState([false, false]);
  const [mintingParagraph, setMintingParagraph] = useState(
    "No whitelist needed. Public minting is now open! 🎉"
  );
  const [mintingHeader, setMintingHeader] = useState("Public Minting Open!!");
  const [whitelistedStatus, setwhitelistedStatus] = useState(false);
  const [mintButtonClicked, setMintButtonStatus] = useState(false);
  const [mintNumberInput, setMintNumberInput] = useState(1);
  // const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
  // const PRIVATE_KEY = process.env.REACT_APP_PRIVATE_INFURA_KEY;
  const AUTH = process.env.REACT_APP_NFTPORT_API_KEY;
  let calledOnce = false;

  async function getMerkleProof() {
    if (!calledOnce) {
      calledOnce = true;
      countdown();
      console.log(
        "HERE: ",
        infoData?.information?.runtimeConfig?.publicMintStart
      );

      await fetch(
        "https://api.nftport.xyz/v0/me/contracts/collections?" + query,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: AUTH,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          const merkleProofs = data.contracts[1].merkle_proofs;
          const merkleProof = merkleProofs[wallet.toLowerCase()] || [];

          setProofData(merkleProof);
        })
        .catch((error) => {});
    }
  }
  getMerkleProof();
  useEffect(() => {
    if (mintStatus[0]) {
      setMintingButtonActive(true);
      setMintingHeader("Public Minting Open!!");
      document.body.classList.add("bg-salmon");
      setMintingParagraph(
        "No whitelist needed. Public minting is now open! 🎉"
      );
      handleTotalPrice();
    } else if (mintStatus[1]) {
      setMintingButtonActive(true);
      setStartTimeState(infoData?.information?.runtimeConfig?.publicMintStart);
      setMintingParagraph("Pre-Sale Minting Open!!");
      setPresaleCountdown("Public Minting Countdown");

      if (!whitelistedStatus) {
        setMintingParagraph("You are not whitelisted for the pre-sale");
      } else {
        setMintingParagraph("You're on the whitelist for the pre-sale! 🎉");
      }
    } else {
      setMintingButtonActive(false);
      setStartTimeState(infoData?.information?.runtimeConfig?.presaleMintStart);
      setMintingHeader("NFT Drop Coming Soon!!");
      setPresaleCountdown("Pre-Sale Minting Countdown");
      setMintingParagraph(
        "We are working hard to launch the NFT Collection. Stay tuned for updates!"
      );
    }

    // console.log("proofData:", proofData);
    async function getInfo() {
      const publicMintActive = await readContract({
        address: contractAddress,
        abi: abi,
        functionName: "mintingActive",
        watch: true,
      });

      const presaleMintActive = await readContract({
        address: contractAddress,
        abi: abi,
        functionName: "presaleActive",
        watch: true,
      });
      setMintStatus([publicMintActive, presaleMintActive]);
      console.log("publicMintActive: ", publicMintActive);
      console.log("presaleMintActive: ", presaleMintActive);

      const information = await readContract({
        address: contractAddress,
        abi: abi,
        functionName: "getInfo",
      });

      // console.log("isWhitelisted: ", whitelisted);

      // const presaleMintTransaction presaleMint
      // const whitelisted isWhitelisted

      setData({
        information,
      });
    }

    async function getStatusWhitelisted() {
      // console.log("PROOF:", proofData);
      const whitelisted = await readContract({
        address: contractAddress,
        abi: abi,
        functionName: "isWhitelisted",
        args: [wallet, proofData],
      });
      setwhitelistedStatus(whitelisted);
    }
    if (proofData !== undefined && Object.keys(proofData).length !== 0) {
      getStatusWhitelisted();
    }
    getInfo();

    handleTotalPrice();
  }, [
    proofData,
    startTimeState,
    mintNumberInput,
    mintStatus,
    infoData?.information?.runtimeConfig?.publicMintStart,
    infoData?.information?.runtimeConfig?.presaleMintStart,
  ]);

  async function mint() {
    if (isConnected) {
      setMintButtonStatus(true);

      const amount = mintNumberInput;
      const value = infoData?.information?.runtimeConfig?.publicMintPrice * 5n;
      console.log("TOTAL:", value);
      console.log("amount:", amount);

      try {
        const mintTransaction = await writeContract({
          address: contractAddress,
          abi: abi,
          functionName: "mint",
          args: [amount],
          from: wallet,
          value: value,
        });

        if (mintTransaction) {
          toast.success("Mint Succesfull! 🎉", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        } else {
          setMintButtonStatus(false);
          toast.error("Mint Failed", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        }
      } catch (e) {
        console.log("ERROR IS HERE:", e);
        toast.error("Mint Failed", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
        setMintButtonStatus(false);
      }
    } else {
      toast.error("Please connect your wallet!", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }
  }

  function handleTotalPrice() {
    if (
      mintNumberInput < 1 ||
      mintNumberInput > infoData?.deploymentConfig?.tokensPerMint
    ) {
      setTotalPrice("INVALID QUANTITY");
      setMintButtonStatus(true);
      return;
    }
    setTotalPrice((mintNumberInput * 0.05).toFixed(2) + " ETH");
  }

  function increaseMints() {
    if (mintNumberInput < maxMints) {
      setMintNumberInput((prevmintNumberInput) => prevmintNumberInput + 1);
    } else {
      toast.error("Amount can't be less than 1 or greater than 10", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }
  }
  function decreaseMints() {
    if (mintNumberInput > 1 && mintNumberInput <= maxMints) {
      setMintNumberInput((prevmintNumberInput) => prevmintNumberInput - 1);
    } else {
      toast.error("Amount can't be less than 1 or higher than 10", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }
  }

  function handleChangeInput(e) {
    if (e.target.value >= 1 && e.target.value <= 10) {
      setMintNumberInput(+e.target.value);
    } else {
      toast.error("Amount can't be less than 1 or higher than 10", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }
  }

  function handleMaxMints() {
    setMintNumberInput(maxMints);
  }

  console.log("DATA:", infoData);

  return (
    <>
      <div className="mx-auto  rounded-xl shadow-md overflow-hidden">
        <div className="text-white">
          <div className="grid grid-cols-2 ">
            <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto   flex flex-col justify-center">
              {" "}
              <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
                Habibi Plates: Exquisite NFT Collectibles
              </h1>
              <p className="colorText whiteParagraph md:text-2xl text-xl font-bold text-gray-500">
                Own Exclusiveness digital supercar plates with our luxury NFT
                collection
              </p>
              <p className="colorText whiteParagraph md:text-2xl text-xl font-bold text-gray-500">
                Luxurious supercar plates for discerning
              </p>
              <div className="flex ">
                <p className="md:text-5xl sm:text-4xl text-xl font-bold">
                  You get
                </p>
                <Typed
                  className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4"
                  strings={["Emotions", "Exclusiveness", "Value"]}
                  typeSpeed={120}
                  backSpeed={140}
                  loop
                />
              </div>
              <div className="secure_wallet">
                {mintStatus?.presaleMintActive
                  ? "Pre-Sale Minting Open!"
                  : "Pre-Sale Minting is not open"}
              </div>
              <div className="secure_wallet">100% Secure and Verified</div>
              <p className="whiteParagraph md:text-2xl text-xl font-bold text-gray-500">
                Habibi plates is a collection of 10,000 unique Habibi plates
                NFTs— unique digital collectibles living on the Ethereum
                blockchain.
              </p>
            </div>

            <div className="flex justify-center items-center">
              <Tilt>
                <div className="below">
                  <img
                    className=" w-[500px] mx-auto my-4 habibiSecondImage"
                    width="1500px"
                    height="331px"
                    alt=""
                  />
                </div>
              </Tilt>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 cusstom-cols-1">
          <div className="grid  ">
            <section class="container countdown-container">
              <div class="countdown minting-card show-card">
                {startTimeState ? (
                  <div id="countdown" data-date={startTimeState}>
                    <ul class="count-down" data-date={startTimeState}>
                      <li class="clock-item">
                        <span class="count-number days">-</span>
                        <p class="count-text">Days</p>
                      </li>

                      <li class="clock-item">
                        <span class="count-number hours">-</span>
                        <p class="count-text">Hours</p>
                      </li>

                      <li class="clock-item">
                        <span class="count-number minutes">-</span>
                        <p class="count-text">Min</p>
                      </li>

                      <li class="clock-item">
                        <span class="count-number seconds">-</span>
                        <p class="count-text">Sec</p>
                      </li>
                    </ul>
                    <h2 id="subHeading">{presaleCountdown}</h2>
                  </div>
                ) : (
                  "Loading"
                )}
                <h1 id="mainHeading">{mintingHeader}</h1>
                <p id="mainText">{mintingParagraph}</p>
                <div id="mintContainer" class="mint-container">
                  <div class="info-container">
                    <div class="secure_wallet">
                      <h3>Supply</h3>
                      <p id="totalSupply">10000</p>
                    </div>
                    <div class="secure_wallet">
                      <h3>Price Per Mint</h3>
                      <p id="pricePerMint">0.5 ETH</p>
                    </div>
                    <div class="secure_wallet">
                      <h3>Max</h3>
                      <p id="maxPerMint">10</p>
                    </div>
                  </div>
                  <div class="mint-qty">
                    <span>
                      <button
                        class="input-number-decrement customButton"
                        id="mintDecrement"
                        onClick={decreaseMints}
                      >
                        –
                      </button>
                      <input
                        id="mintInput"
                        class="input-number"
                        type="number"
                        onChange={handleChangeInput}
                        value={mintNumberInput}
                        min="1"
                        max="10"
                      />
                      <button
                        class="input-number-increment customButton"
                        id="mintIncrement"
                        onClick={increaseMints}
                      >
                        +
                      </button>
                    </span>
                    <button
                      onClick={handleMaxMints}
                      id="setQtyMax"
                      class="customButton"
                    >
                      SET MAX
                    </button>
                  </div>
                  <div class="secure_wallet alignRight">
                    <h3 class="your-total">Total:</h3>
                    <p id="totalPrice">{totalPrice}</p>
                  </div>
                  <button
                    id="mintButton"
                    disabled={mintingButtonActive ? true : ""}
                    className={
                      mintingButtonActive
                        ? "disabledButton hero-btn customButton mint-btn"
                        : " hero-btn customButton mint-btn"
                    }
                    onClick={mint}
                  >
                    Mint Your NFT
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 cusstom-cols-1">
          <div className="grid  ">
            <h1 className="h1-HudatTullah md:text-7xl sm:text-6xl text-4xl center md:py-6">
              How to mint
            </h1>

            <div className="grid grid-cols-1 md:grid-cols-2 cusstom-cols-2">
              <div className="grid  gap-4">
                <div>
                  <img
                    alt=""
                    className="h-auto max-w-full rounded-lg image-confirm-transaction"
                    src="/assets/habibi.jpeg"
                  />
                </div>
              </div>
              <div className="grid grid-images gap-4">
                <div>
                  <img
                    className="h-auto max-w-full rounded-lg image-connect-wallet"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <WagmiConfig config={wagmiConfig}></WagmiConfig>

        <div className="grid grid-cols-1 md:grid-cols-1 cusstom-cols-1">
          <div className="grid  ">
            <h1
              id="roadmap"
              className="h1-HudatTullah md:text-7xl sm:text-6xl text-4xl center md:py-6"
            >
              Roadmap
            </h1>
          </div>
        </div>
        <script
          src="https://kit.fontawesome.com/4381c59eae.js"
          crossorigin="anonymous"
        ></script>
        <link
          href="https://fonts.googleapis.com/css?family=Poppins:200&subset=latin"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Poppins:800&subset=latin"
          rel="stylesheet"
          type="text/css"
        />
        <div className="container">
          <div className="main-timeline">
            <div className="timeline_item">
              <div className="date-content order_1b">
                <div className="date-outer">
                  <span className="date">
                    <p className="exact_date">Sep 2023</p>
                  </span>
                </div>
              </div>
              <div className="icon order_2b"></div>
              <div className="timeline-content order_3b col-md">
                <p className="title">The World Wide Web begins</p>
                <p className="description">
                  Tim Berners-Lee, invents the “World Wide Web” as an easy way
                  to share information. Though we often use the “Internet” and
                  the “Web” interchangeably, they don’t actually refer to the
                  same thing. The Internet hosts the Web, which was
                  Berners-Lee’s breakthrough.
                </p>
              </div>
            </div>
            <div className="timeline_item">
              <div className="date-content order_1">
                <div className="date-outer">
                  <span className="date">
                    <p className="exact_date">Oct 2023</p>
                  </span>
                </div>
              </div>
              <div className="icon order_2"></div>
              <div className="timeline-content order_3 col-md">
                <p className="title">Wikipedia opens to the world</p>
                <p className="description">
                  The beginning of the end for encyclopedia salesmen. Wikipedia
                  launched with its first edit on January 15, 2001, and fast
                  became the go-to source of information. By 2006, the site had
                  published over 1 million articles and other internet
                  enretpreneurs followed shortly.
                </p>
              </div>
            </div>
            <div className="timeline_item">
              <div className="date-content order_1b">
                <div className="date-outer">
                  <span className="date">
                    <p className="exact_date">Nov 2023</p>
                  </span>
                </div>
              </div>
              <div className="icon order_2b"></div>
              <div className="timeline-content order_3b col-md">
                <p className="title">Internet users worldwide</p>
                <p className="description">
                  As of October 2022, there were 5.07 billion active internet
                  users worldwide – almost 59.6 percent of the global
                  population. Ask any of them what life would be like without
                  the net and the answer will likely be either ‘unimaginable’ or
                  ‘very, very boring’.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 cusstom-cols-1">
          <div className="grid  ">
            <h1
              id="team"
              className="h1-HudatTullah md:text-7xl sm:text-6xl text-4xl center md:py-6"
            >
              Meet the team
            </h1>
          </div>
        </div>
        <section id="team_cards">
          <div className="container py-2">
            <div className="row pb-4"></div>
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-4 pt-5">
                <div className="our_team shadow-sm border-0">
                  <div className="card-body">
                    <div className="user-picture">
                      <img
                        src="https://images.unsplash.com/photo-1495603889488-42d1d66e5523?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=130&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=130"
                        className="shadow-sm rounded-circle"
                        height="130"
                        width="130"
                        alt=""
                      />
                    </div>
                    <div className="user-content">
                      <h5 className="text-capitalize user-name">
                        Vasilis Kolokythas
                      </h5>
                      <p className=" text-capitalize text-muted small blockquote-footer">
                        Web developer
                      </p>
                      <div className="small">
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star-half-alt text-warning"></i>
                        <i className="fas fa-star text-light"></i>
                      </div>
                      <p className="small text-muted mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Amet nemo harum repellendus aut itaque. Temporibus
                        quaerat dolores ut, cupiditate molestiae commodi!
                        Distinctio praesentium, debitis aut minima doloribus
                        earum quia commodi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 pt-5">
                <div className="our_team shadow-sm border-0">
                  <div className="card-body">
                    <div className="user-picture">
                      <img
                        src="https://images.unsplash.com/photo-1582003457856-20898dd7e1ea?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=130&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=130"
                        className="shadow-sm rounded-circle"
                        height="130"
                        width="130"
                        alt=""
                      />
                    </div>
                    <div className="user-content">
                      <h5 className="text-capitalize user-name">
                        Stavros Sideris
                      </h5>
                      <p className=" text-capitalize text-muted small blockquote-footer">
                        Web developer
                      </p>
                      <div className="small">
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star-half-alt text-warning"></i>
                        <i className="fas fa-star text-light"></i>
                        <i className="fas fa-star text-light"></i>
                      </div>
                      <p className="small text-muted mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Amet nemo harum repellendus aut itaque. Temporibus
                        quaerat dolores ut, cupiditate molestiae commodi!
                        Distinctio praesentium, debitis aut minima doloribus
                        earum quia commodi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 pt-5">
                <div className="our_team shadow-sm border-0">
                  <div className="card-body">
                    <div className="user-picture">
                      <img
                        src="https://images.unsplash.com/photo-1492447166138-50c3889fccb1?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=130&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=130"
                        className="shadow-sm rounded-circle"
                        height="130"
                        width="130"
                        alt=""
                      />
                    </div>
                    <div className="user-content">
                      <h5 className="text-capitalize user-name">
                        Petros Kalopoulos
                      </h5>
                      <p className=" text-capitalize text-muted small blockquote-footer">
                        Web developer
                      </p>
                      <div className="small">
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star-half-alt text-warning"></i>
                      </div>
                      <p className="small text-muted mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Amet nemo harum repellendus aut itaque. Temporibus
                        quaerat dolores ut, cupiditate molestiae commodi!
                        Distinctio praesentium, debitis aut minima doloribus
                        earum quia commodi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 pt-5">
                <div className="our_team shadow-sm border-0">
                  <div className="card-body">
                    <div className="user-picture">
                      <img
                        src="https://i.picsum.photos/id/836/130/130.jpg?hmac=Sot_REUw5W-XSuE6FmCjT9JenhZfiNqZYs3AQbfrZsc"
                        className="shadow-sm rounded-circle"
                        height="130"
                        width="130"
                        alt=""
                      />
                    </div>
                    <div className="user-content">
                      <h5 className="text-capitalize user-name">
                        Dominik Oslej
                      </h5>
                      <p className=" text-capitalize text-muted small blockquote-footer">
                        Web developer
                      </p>
                      <div className="small">
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star-half-alt text-warning"></i>
                        <i className="fas fa-star text-light"></i>
                        <i className="fas fa-star text-light"></i>
                        <i className="fas fa-star text-light"></i>
                      </div>
                      <p className="small text-muted mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Amet nemo harum repellendus aut itaque. Temporibus
                        quaerat dolores ut, cupiditate molestiae commodi!
                        Distinctio praesentium, debitis aut minima doloribus
                        earum quia commodi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 pt-5">
                <div className="our_team shadow-sm border-0">
                  <div className="card-body">
                    <div className="user-picture">
                      <img
                        src="https://images.unsplash.com/photo-1495603889488-42d1d66e5523?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=130&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=130"
                        className="shadow-sm rounded-circle"
                        height="130"
                        width="130"
                        alt=""
                      />
                    </div>
                    <div className="user-content">
                      <h5 className="text-capitalize user-name">Nadia Zerva</h5>
                      <p className=" text-capitalize text-muted small blockquote-footer">
                        Web developer
                      </p>
                      <div className="small">
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star-half-alt text-warning"></i>
                        <i className="fas fa-star text-light"></i>
                      </div>
                      <p className="small text-muted mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Amet nemo harum repellendus aut itaque. Temporibus
                        quaerat dolores ut, cupiditate molestiae commodi!
                        Distinctio praesentium, debitis aut minima doloribus
                        earum quia commodi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 pt-5">
                <div className="our_team shadow-sm border-0">
                  <div className="card-body">
                    <div className="user-picture">
                      <img
                        src="https://images.unsplash.com/photo-1582003457856-20898dd7e1ea?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=130&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=130"
                        className="shadow-sm rounded-circle"
                        height="130"
                        width="130"
                        alt=""
                      />
                    </div>
                    <div className="user-content">
                      <h5 className="text-capitalize user-name">
                        Thanasis Gemos
                      </h5>
                      <p className=" text-capitalize text-muted small blockquote-footer">
                        Web developer
                      </p>
                      <div className="small">
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star-half-alt text-warning"></i>
                        <i className="fas fa-star text-light"></i>
                        <i className="fas fa-star text-light"></i>
                      </div>
                      <p className="small text-muted mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Amet nemo harum repellendus aut itaque. Temporibus
                        quaerat dolores ut, cupiditate molestiae commodi!
                        Distinctio praesentium, debitis aut minima doloribus
                        earum quia commodi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="message">
          Sorry, your browser does not support CSS Grid. 😅
        </div>
        <div className="grid grid-images grid-cols-1 md:grid-cols-1 cusstom-cols-1">
          <div className="grid  ">
            <h1
              id="collection"
              className="h1-HudatTullah md:text-7xl sm:text-6xl text-4xl center md:py-6"
            >
              The Collection
            </h1>
          </div>
        </div>
        <Swiper
          initialSlide={2}
          loop={true}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: -25,
            stretch: 0,
            depth: 100,
            modifier: 1,
            scale: 0.95,
            slideShadows: true,
          }}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img
              src="https://images.pexels.com/photos/1624496/pexels-photo-1624496.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="https://images.pexels.com/photos/1624496/pexels-photo-1624496.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://swiperjs.com/demos/images/nature-3.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://swiperjs.com/demos/images/nature-4.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://swiperjs.com/demos/images/nature-5.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://swiperjs.com/demos/images/nature-6.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://swiperjs.com/demos/images/nature-8.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://swiperjs.com/demos/images/nature-9.jpg" alt="" />
          </SwiperSlide>
        </Swiper>
        <ToastContainer />
        <WagmiConfig config={wagmiConfig}></WagmiConfig>
      </div>
    </>
  );
};

export default Hero;
