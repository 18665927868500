import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, Web3Button } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { goerli } from "wagmi/chains";

const chains = [goerli];
const projectId = process.env.REACT_APP_PROJECT_ID;
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);
const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  function HomePage() {
    return <Web3Button />;
  }

  // function HomePage() {
  //   const { open, close } = useWeb3Modal();

  //   return (
  //     <button className="customButton" onClick={() => open()}>
  //       Connect
  //     </button>
  //   );
  // }

  //div => max-w-[1240px    h1 => w-full

  return (
    <div className="flex justify-center max-w-[1240px] w-full gap-20 items-center h-24 ] mx-auto px-4 text-white">
      {nav ? (
        ""
      ) : (
        <h1 className="logo text-3xl font-bold text-[#00df9a]">
          Habibi Plates
        </h1>
      )}
      <ul className="hidden md:flex">
        <li className="p-4">Minting</li>
        <li className="p-4">About</li>
        <li className="p-4">
          <a href="/#collection">Collection </a>
        </li>
        <li className="p-4">
          {" "}
          <a href="/#roadmap">Roadmap </a>
        </li>
        <li className="p-4">
          <a href="/#team">Team </a>
        </li>
        <li>
          <WagmiConfig config={wagmiConfig}>
            <HomePage />
          </WagmiConfig>

          <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </li>
      </ul>

      <div
        onClick={handleNav}
        className={
          nav
            ? "fixed block md:hidden show-z-index cursor-pointer x-button "
            : "block md:hidden cursor-pointer custom-padding"
        }
      >
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <ul
        className={
          nav
            ? "mobile-nav fixed left-0 top-0 w-[100%] h-full bg-[#000300] ease-in-out duration-500"
            : "stand-out ease-in-out duration-500 fixed left-[-100%] fixed top-0 w-[60%] h-full bg-[#000300]"
        }
      >
        <h1 className="logo text-3xl font-bold text-[#00df9a]">
          Habibi Plates
        </h1>
        <li className="p-4">Minting</li>
        <li className="p-4">About</li>
        <li className="p-4">
          <a href="/#collection">Collection </a>
        </li>
        <li className="p-4">
          <a href="/#roadmap">Roadmap </a>
        </li>
        <li className="p-4">
          <a href="/#team">Team </a>
        </li>
        <li className="p-4">
          <WagmiConfig config={wagmiConfig}>
            <HomePage />
          </WagmiConfig>

          <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
