import React, { useState, useEffect, useCallback } from "react";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import { countdown } from "./components/countdown";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, Web3Button } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { goerli } from "wagmi/chains";

var startTimeState = 1717493286n;
const chains = [goerli];
const projectId = process.env.REACT_APP_PROJECT_ID;
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);
var under_construction = true;
console.log("startTimeState: ", startTimeState);
console.log("under construction" , under_construction);
function App() {
  useEffect(() => {
    countdown();
  }, []);
  return (
    <div>
      <WagmiConfig config={wagmiConfig}>
        {under_construction ? (
          <div className="grid grid-cols-1 md:grid-cols-1 cusstom-cols-1">
            <div className="grid  ">
              <section className="container countdown-container">
                <div className="countdown minting-card show-card">
                  <div id="countdown" data-date={startTimeState}>
                    <h1 className="logo text-3xl font-bold text-[#00df9a]">
                      Habibi Plates
                    </h1>
                    <h1>coming soon</h1>
                    {/* <ul className="count-down" data-date={startTimeState}>
                      <li className="clock-item">
                        <span className="count-number days">-</span>
                        <p className="count-text">Days</p>
                      </li>

                      <li className="clock-item">
                        <span className="count-number hours">-</span>
                        <p className="count-text">Hours</p>
                      </li>

                      <li className="clock-item">
                        <span className="count-number minutes">-</span>
                        <p className="count-text">Min</p>
                      </li>

                      <li className="clock-item">
                        <span className="count-number seconds">-</span>
                        <p className="count-text">Sec</p>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </section>
            </div>
          </div>
        ) : (
          <>
            <Navbar />
            <Hero />
            <Footer />
          </>
        )}
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </div>
  );
}

export default App;
